import React from "react"
import { Link } from "gatsby"
import { VerticalLogo } from "../Logo"

const Header = () => (
  <header className="container mx-auto relative flex items-center justify-center">
    <div className="p-2">
      <VerticalLogo />
    </div>
    <div style={{ position: "absolute", right: 0 }}>
      <h1 style={{ margin: 0 }}>
        {/* <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          Sign In
        </Link> */}
      </h1>
    </div>
  </header>
)

export { Header }
