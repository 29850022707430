// @ts-check
import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import { Phone } from "../components/Phone"
import { Header } from "../components/Header"
import SEO from "../components/seo"

const Arrow = () => {
  return (
    <svg
      width="56"
      height="22"
      viewBox="0 0 56 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.1031 0.437513L55.5645 9.94375C56.1452 10.5271 56.1452 11.4729 55.5645 12.0562L46.1031 21.5625C45.5225 22.1458 44.5811 22.1458 44.0005 21.5625C43.4199 20.9791 43.4199 20.0333 44.0005 19.45L50.924 12.4938H0V9.50624H50.924L44.0005 2.55001C43.4199 1.96666 43.4199 1.02086 44.0005 0.437513C44.5811 -0.145838 45.5225 -0.145838 46.1031 0.437513Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      className="min-h-screen flex flex-col"
      style={{
        background:
          "radial-gradient(57.5% 57.5% at 50% 100%, #FFF4BC 0%, #ED6D68 25.52%, #3C44D1 45.31%, #1E1453 100%)",
      }}
    >
      <Header />
      <div
        className="flex-grow mt-20 px-4 container mx-auto border-azul-900 shadow-lg"
        style={{ background: "rgba(30, 20, 83, 0.5)" }}
      >
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <div className="mx-auto" style={{ maxWidth: 500 }}>
              <h1 className="mt-8 text-6xl leading-none text-azul-100 font-medium">
                Grow <br />
                Organically
              </h1>
              <p className="mt-8 text-azul-300">
                Sol is the easiest way for brands and influencers to connect and
                run campaigns.
              </p>
              <div className="mt-8 max-w-xs mx-auto">
                <a
                  href="https://jon042455.typeform.com/to/I0iFNU"
                  className="block w-full bg-azul-900 shadow-lg text-left leading-tight px-5 py-4 font-medium text-4xl text-gold-500 border border-gold-500 rounded"
                >
                  <div className="relative">
                    <div className="absolute top-0 right-0 p-2">
                      <Arrow />
                    </div>
                  </div>
                  For <br /> Influencers
                </a>
              </div>
              <div className="mt-8 max-w-xs mx-auto">
                <a
                  href="https://jon042455.typeform.com/to/kNMXWP"
                  className="block w-full bg-azul-900 shadow-lg text-left leading-tight px-5 py-4 font-medium text-4xl text-salmon-500 border border-salmon-500 rounded"
                >
                  <div className="relative">
                    <div className="absolute top-0 right-0 p-2">
                      <Arrow />
                    </div>
                  </div>
                  For <br /> Brands
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 py-20">
            <Phone>
              <Image />
            </Phone>
          </div>
        </div>
      </div>
    </div>
    {/* <Header /> */}
  </Layout>
)

export default IndexPage
