// @ts-check
import React from "react"
import "./Phone.css"

const Phone = ({ children }) => {
  return (
    <div className="Phone">
      <div className="Phone__screen">{children}</div>
    </div>
  )
}

export { Phone }
